<template>
  <div class="faceBinding">
    <!-- faceBinding 人脸绑定 -->
    <div class="nav">
      <div class="nav_title">人脸绑定</div>
      <div class="nav_left_btn" @click="toBack">
        <img :src="cancel" alt width="15" height="25" />
      </div>
    </div>
    <div class="faceBinding-main">
      <!-- <div @click="toPage('相机')">人脸绑定(拍照)</div>
      <div @click="toPage('FaceBind')">人脸绑定(相册)</div>-->
      <div v-if="!isAndroid" class="items">
        <div class="item-flex" @click="handler">
          <div>人脸绑定</div>
        </div>
        <div v-show="false">
          <van-uploader
            ref="uploader"
            v-model="fileList"
            :after-read="(file) => onLoadCard(file, 'front')"
            :max-count="1"
            :max-size="50 * 1024 * 1024"
            :preview-image="false"
            @delete="onRemove"
          />
        </div>
      </div>
      <div v-if="isAndroid" class="items">
        <div class="item-flex" @click="handlerImage">
          <div>人脸绑定(拍照)</div>
        </div>
        <div v-show="false">
          <van-uploader
            ref="uploaderImage"
            v-model="fileList"
            :after-read="(file) => onLoadCard(file, 'front')"
            :max-count="1"
            :max-size="50 * 1024 * 1024"
            :preview-image="false"
            @delete="onRemove"
          />
        </div>
        <!-- <el-upload
          class="avatar-uploader"
          :headers="headers"
          name="img"
          :action="apiUrl + `/upload/avatar?userId=${data.userId}&manager=${data.manager}`"
          :show-file-list="false"
          :on-success="handleAvatarSuccess"
          :before-upload="beforeAvatarUpload"
        >
          <div class="item-flex">
            <div>人脸绑定(拍照)/upload/avatar {{data.userId}}{{data.manager}}</div>
          </div>
        </el-upload>-->
      </div>
      <div v-if="isAndroid" class="items">
        <div class="item-flex" @click="handlerAlbum">
          <div>人脸绑定(相册)</div>
        </div>
        <div v-show="false">
          <van-uploader
            ref="uploaderAlbum"
            v-model="fileList"
            accept="album/*"
            :after-read="(file) => onLoadCard(file, 'front')"
            :max-count="1"
            :max-size="50 * 1024 * 1024"
            :preview-image="false"
            @delete="onRemove"
          />
        </div>
      </div>
      <div class="items">
        <div class="item-flex">
          <div @click="toPage('FaceCheckList')">申请记录</div>
        </div>
      </div>
      <div class="faceExplain">
        <h2>人脸照片正确示例:</h2>
        <div>
          <img src="../../assets/人脸.jpg" height="200rpx" alt />
        </div>
        <h2>详细说明:</h2>
        <ul>
          <li>1.近期人脸正面免冠照,露出眉毛和眼睛。</li>
          <li>2.照片最好选择白底或蓝底、无逆光、无PS、无过度美颜处理。</li>
          <li>3.人脸占整个画面的50%以上。</li>
          <li>4.不使用全身照、风景为背景等照片。</li>
          <li>5.照片格式为jpg,大小不超过10M。</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import cancel from "@/assets/cancel.png";
import { Toast } from "vant";
import { onloadImage, updateEmployeePhoto } from "@/api/api";
import { getAccInfo } from "@/api/person";

export default {
  data() {
    return {
      cancel,
      fileList: [],
      personInfo: {
        id: null,
        classManager: null,
        departmentManager: null,
      },
      form: {
        current: 1,
        size: 10,
      },
      message: "",
      imageUrl: "",
      data: {
        userId: "",
        manager: "",
      },
      apiUrl: process.env.VUE_APP_BASE_API,
      headers: {
        Authorization: "",
        ContentType: "application/json;",
      },
    };
  },
  computed: {
    isAndroid() {
      return navigator.userAgent.indexOf("Android") > -1;
    },
  },
  created() {
    this.headers = {
      Authorization: "Bearer " + this.$store.getters.token,
    };
    console.log(this.headers);

    this.toInfo();
    this.personInfo = JSON.parse(sessionStorage.getItem("personInfo"));
    this.data = {
      userId: this.personInfo.id,
      manager: this.personInfo.classManager
        ? this.personInfo.classManager.id
        : "" || this.personInfo.departmentManager
        ? this.personInfo.departmentManager.id
        : "",
    };
    console.log(this.data);

    // console.log('人脸绑定', this.personInfo)
    // console.log('zj:', this.personInfo.id)
    // console.log('ld:', this.personInfo.classManager ? this.personInfo.classManager.id : this.personInfo.departmentManager.id)
  },
  methods: {
    toHome() {
      this.$router.back();
    },
    toPage(name) {
      this.$router.push({ name });
      // console.log(name)
    },
    toBack() {
      this.$router.back();
    },
    // 获取登录用户信息
    async toInfo() {
      const res = await getAccInfo();
      // console.log(res)
      if (res.code === 0) {
        this.$nextTick(() => {
          this.infoData = res.data;
          sessionStorage.setItem("personInfo", JSON.stringify(this.infoData));
        });
      }
    },

    // _______________________________________________________________
    async onLoadCard(file) {
      console.log(file, "file");
      console.log(file.content, "file.content");

      file.status = "uploading";
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      let res;
      try {
        // 采集人脸上传的接口（存储图片地址）
        res = await onloadImage({
          fileName: file.file.name,
          userId: this.personInfo.id,
          // manager: '' || this.personInfo.classManager.id || this.personInfo.departmentManager.id,
          // manager: this.personInfo.classManager ? this.personInfo.classManager.id : this.personInfo.departmentManager.id,
          manager: this.personInfo.classManager
            ? this.personInfo.classManager.id
            : "" || this.personInfo.departmentManager
            ? this.personInfo.departmentManager.id
            : "",
          image: file.content.split(",")[1],
        });
        // console.log('res', res)
        const { code } = res;
        if (code === 0) {
          this.loadImg = true;
          file.status = "success";
          this.message = res.message;
          // Toast.success(this.message)
        } else {
          file.status = "failed";
          this.message = res.message;
          // Toast.fail(this.message)
        }
      } catch (error) {
        Toast.clear();
        // console.log('上传', error)
        file.status = "failed";
        this.message = "上传失败";
        this.uploading = false;
        return;
      }
      this.uploading = false;

      // 更新人脸的接口（将存储的图片地址更新到人脸）
      const { data } = res;
      let response;
      try {
        response = await updateEmployeePhoto({
          photo: data.name,
          manager: this.personInfo.classManager
            ? this.personInfo.classManager.id
            : "" || this.personInfo.departmentManager
            ? this.personInfo.departmentManager.id
            : "",
        });
        // console.log('response', response)
      } catch (error) {
        Toast.clear();
        file.status = "failed";
        Toast.fail(this.message);
        this.uploading = false;
        return;
      }
      Toast.clear();
      const { code } = response;
      if (code === 0) {
        this.loadImg = true;
        file.status = "success";
        this.toInfo();
        Toast.success("上传成功");
      } else {
        file.status = "failed";
      }
      this.uploading = false;
    },
    // ________________________________________________________________

    onRemove() {
      this.fileImg = "";
    },

    // 人脸绑定
    handler() {
      if (this.fileList.length !== 1) {
        this.$refs.uploader.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handler());
      }
    },

    // 人脸绑定（拍照）
    handlerImage() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderImage.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerImage());
      }
    },

    // 人脸绑定（相册）
    handlerAlbum() {
      if (this.fileList.length !== 1) {
        this.$refs.uploaderAlbum.chooseFile();
      } else {
        this.fileList = [];
        this.$nextTick(() => this.handlerAlbum());
      }
    },
    // handleAvatarSuccess(res, file) {
    //   console.log(res);
    // },
    // beforeAvatarUpload(file, type) {
    //   console.log(file, type);

    //   this.data = {
    //     fileName: file.name,
    //     userId: this.personInfo.id,
    //     manager: this.personInfo.classManager
    //       ? this.personInfo.classManager.id
    //       : "" || this.personInfo.departmentManager
    //       ? this.personInfo.departmentManager.id
    //       : "",
    //     image: "",
    //   };
    //   console.log(this.data, "this.data");
    // },
  },
};
</script>

<style lang="scss" scoped>
.faceBinding {
  // background: #f1f1f1;
  height: 100%;
  display: flex;
  flex-direction: column;
  .nav {
    z-index: 999;
    position: fixed;
    top: 0;
    width: 100%;
    display: block;
    text-align: center;
    height: 48px;
    color: white;
    overflow: hidden;
    border-bottom: 1px solid rgba(181, 201, 210, 0.6);
    background-color: #ffffff;

    & > .nav_title {
      display: block;
      font-size: 1.1rem;
      overflow: hidden;
      white-space: nowrap;
      line-height: 48px;
      text-overflow: ellipsis;
      color: #607483;
      width: 60%;
      margin: 0 auto;
      background-color: #ffffff;
    }

    & > .nav_left_btn {
      float: left;
      position: absolute;
      border-width: 0.9rem 0.5rem 0.9rem 0.7rem;
      border-color: transparent;
      background-size: 1.2rem 2rem;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 0.3rem 0 0.8rem;
      top: 9px;
      left: 0px;
      min-height: 30px;
      display: flex;
      align-items: center;

      & > .back {
        font-size: 1.1rem;
        margin-left: 3px;
      }
    }

    & > .nav_left_btn:active {
      opacity: 0.5;
    }

    & > .nav_right_btn {
      float: right;
      position: absolute;
      border-width: 9px 4px 9px 3px;
      border-color: transparent;
      background: transparent;
      line-height: 1rem;
      font-size: 1rem;
      color: #607483;
      padding: 0 8px 0 2px;
      top: 8px;
      right: 0;
      min-width: 52px;
      min-height: 30px;
      display: flex;
      align-items: center;
    }

    & > .nav_right_btn_click {
      opacity: 0.5;
    }
  }
  .faceBinding-main {
    flex: 1;
    box-sizing: border-box;
    padding: 4rem 0 0 0;
    background: #f3f3f3;
    .item-flex {
      background: #fff;
      box-sizing: border-box;
      padding: 0 1rem;
      height: 3rem;
      line-height: 3rem;
      border: 1px solid rgba(181, 201, 210, 0.6);
      margin-bottom: 0.2rem;
    }
    & > .faceExplain {
      padding: 1rem;
      h2 {
        color: mediumturquoise;
        font-weight: 700;
        font-size: 1.3rem;
      }
      & > ul {
        li {
          font-size: 1rem;
          margin-bottom: 1.3rem;
          color: black;
        }
      }
    }
  }
}
</style>
